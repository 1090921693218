import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon, { IconTypes } from '../../common/Icon';
import MemberMenu from '../menus/MemberMenu';
import urls from '../../../config/urls';
import Button from '@mui/material/Button';


function SecondaryAuth({ user }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleMemberMenuOpen = () => {
    setIsOpen(true);
  };

  const onClose = (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(false);
  };

  const onDonateSelection = () => {
    window.location = urls.donateFundraiseupStandard
  };

  return (
    <div className="AppHeader-secondaryNavMenu authenticated">
      <div className="AppHeader-navigation non-mobile">
        <Button onClick={onDonateSelection} disableFocusRipple={true} disableRipple={true} variant="contained">
          <span>Donate</span>
        </Button>
      </div>

      <div className="AppHeader-member">
        <div className="AppHeader-menuButton" onClick={handleMemberMenuOpen}>
          <Icon type={IconTypes.UserCircle} />
        </div>
      </div>
      
      <MemberMenu isOpen={isOpen} onClose={onClose} />
    </div>
  );
}

SecondaryAuth.propTypes = {
  user: PropTypes.object
};

function mapStateToProps(state) {
  const { authenticated, firstName, lastName } = state.user;
  return { authenticated, firstName, lastName };
}

export default connect(mapStateToProps)(SecondaryAuth);