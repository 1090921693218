import state from '../config/state';
const { authStatus, responseStatus, messageTypes } = state;

const initialState = {
  accountDeletion: {
    responseStatus: responseStatus.DEFAULT,
    responseCode: null,
    pending: false
  },
  annotations: {
    responseStatus: responseStatus.DEFAULT,
    responseCode: null,
    ids: [],
    byId: {}
  },
  appDisplay: {
    exploreMenuOpen: false,
    pageMessage: {
      time: 0,
      message: '',
      type: messageTypes.INFO
    },
    pageBanner: {
      id: null,
      image: '',
      title: '',
      subtitle: ''
    }
  },
  authentication: {
    responseStatus: authStatus.DEFAULT,
    responseCode: null,
    contextParams: {}
  },
  behavior: {
    currentlyWatching: null,
    mediaJumpPoint: 0,
    favorites: [],
    // watched tracks watching behavior for higher level views
    watchedLoaded: false,
    watched: [],
    // watched ID state tracks watching behavior for media specific views
    watchedIds: [],
    watchedById: {}
  },
  contactRegistration: {
    responseStatus: responseStatus.DEFAULT,
    responseCode: null
  },
  courseProgress: {
    ids: [],
    byId: {}
  },
  courseRegistration: {
    responseStatus: responseStatus.DEFAULT,
    responseCode: null,
    ids: [],
    byId: {}
  },
  courses: {
    ids: [],
    byId: {}
  },
  emailSettings: {
    responseStatus: responseStatus.DEFAULT,
    responseCode: null,
    settings: null
  },
  eventRegistration: {
    responseStatus: responseStatus.DEFAULT,
    responseCode: null,
    ids: [],
    byId: {}
  },
  events: {
    ids: [],
    byId: {}
  },
  instructors: {
    ids: [],
    byId: {}
  },
  magdalaRegistration: {
    responseStatus: responseStatus.DEFAULT,
    responseCode: null,
    communities: null,
    registered: false,
    contextParams: {}
  },
  membershipActivation: {
    responseStatus: responseStatus.DEFAULT,
    responseCode: null,
    passwordToken: '',
    resent: false
  },
  membershipRegistration: {
    responseStatus: responseStatus.DEFAULT,
    responseCode: null,
    token: '',
    check: '',
    registered: false
  },
  posts: {
    ids: [],
    byId: {}
  },
  privacyPolicy: {
    responseStatus: responseStatus.DEFAULT,
    responseCode: null,
    acknowledged: false
  },
  profile: {
    responseStatus: responseStatus.DEFAULT,
    responseCode: null,
    dataUpdated: false,
    data: null
  },
  reflections: {
    ids: [],
    byId: {}
  },
  search: {
    responseStatus: responseStatus.DEFAULT,
    responseCode: null,
    results: null
  },
  share: {
    responseStatus: responseStatus.DEFAULT,
    responseCode: null,
    identified: false,
    eventSlug: null,
    eventLink: null
  },
  systemContent: {
    alert: null,
    annualReport: null,
    featureBanner: null,
    videoPromo: null,
    popup: null,
    privacyPolicy: null,
    webConfig: {}
  },
  taxonomy: {
    responseStatus: responseStatus.DEFAULT,
    responseCode: null,
    results: null
  },
  user: {
    userDataChecked: false,
    authenticated: false,
    trackingId: '',
    type: '',
    email: '',
    salutation: '',
    firstName: '',
    lastName: '',
  }
};

export default initialState;