import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Input from '@mui/material/Input';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import urls from '../../../config/urls';
import validator from '../../../utils/validator';
const { isEmpty } = validator;


function DonationEmbed({ data, onUpdate }) {
  const [frequency, setFrequency] = useState('one-time');
  const [amount, setAmount] = useState(data.amounts[0]);
  const [otherAmount, setOtherAmount] = useState('');

  const openDonationForm = () => {
    window.location = urls.donateFundraiseupStandard;
  };

  const onFrequencyChange = ({ target: { value } }) => {
    setFrequency(value);
    syncUpdate({ frequency: value, amount, otherAmount });
  };

  const onAmountChange = ({ target: { value } }) => {
    setAmount(value);
    setOtherAmount('');
    syncUpdate({ frequency, amount: value, otherAmount: '' });
  };

  const onOtherAmountChange = ({ target: { value } }) => {
    if(!isEmpty(value) && !/^[0-9,]+$/.test(value)) return;
    setOtherAmount(value);
    setAmount('other');
    syncUpdate({ frequency, amount: 'other', otherAmount: value });
  };

  const syncUpdate = data => {
    if(onUpdate) {
      onUpdate({ 
        frequency: data.frequency, 
        amount: data.amount === 'other'? data.otherAmount : data.amount 
      });
    }
  };

  return (
    <div className={`DonationEmbed donation ${data.enableFrequency? 'with-frequency' : 'no-frequency'}`}>
      <div className="frequency">
        <h4>How often would you like to donate?</h4>
        <RadioGroup className="frequency-field" name="frequency" value={frequency || ''} 
          onChange={onFrequencyChange}>
          <FormControlLabel value="one-time" control={<Radio />} label="One Time" />
          <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
        </RadioGroup>
      </div>
      <div className="amount">
        <h4>Donation amount (USD):</h4>
        <RadioGroup className="amount-field" name="amount" value={amount || ''} onChange={onAmountChange}>
          {
            data.amounts.map((amount, index) => (
              <FormControlLabel key={`donation-amount-${index}`} value={amount} control={<Radio />} label={`$${amount}`}/>
            ))
          }
          <div className="other-amount">
            <FormControlLabel control={<Radio />} value="other" label="" />
            <Input type="text" name="otherAmount" placeholder="Other Amount" value={otherAmount} onChange={onOtherAmountChange} />
          </div>
        </RadioGroup>
      </div>
      <div className="action">
        <Button className="DonationHeading-button" color="primary" variant="contained" onClick={openDonationForm}>Donate Now</Button>
      </div>
    </div>
  );
}

DonationEmbed.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func
};

export default DonationEmbed;