import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FullPageSystemPopup from '../layout/popup/FullPageSystemPopup';
import StandardSystemPopup from '../layout/popup/StandardSystemPopup';
import StickySystemPopup from '../layout/popup/StickySystemPopup';
import PopupContent from './popup/PopupContent';
import PopupActions from './popup/PopupActions';
import urls from '../../config/urls';
import { localStore, localRetrieve } from '../../utils/store';
import validator from '../../utils/validator';
const { isNotEmpty } = validator;


function FeaturePopup({ systemContent: { popup }, onClose }) {
  const [init, setInit] = useState(false);
  const [display, setDisplay] = useState(false);
  const [testMode, setTestMode] = useState(false);
  const [type, setType] = useState(null);
  const [displayType, setDisplayType] = useState(null);
  const [key, setKey] = useState(null);
  const [reminder, setReminder] = useState(null);
  const [excludedPaths, setExcludedPaths] = useState(null);
  const [title, setTitle] = useState(null);
  const [snippet, setSnippet] = useState(null);
  const [description, setDescription] = useState(null);
  const [alignment, setAlignment] = useState(null);
  const [data, setData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if(popup) {
      setTestMode(popup.testMode);
      setType(popup.type);
      setDisplayType(popup.displayType);
      setKey(popup.key);
      setReminder(popup.reminder);
      setExcludedPaths(popup.excludedPaths.split(','));
      setTitle(popup.title);

      if(popup.snippet) {
        setSnippet(popup.snippet);
      } else {
        setDescription(popup.description);
        setAlignment(popup.textAlignment);
      }

      if(popup.type === 'donation') {
        let dObj = { ...popup.data };
        dObj.amounts = dObj.amounts.split(',').map(amount => amount.trim());
        setData(dObj);
      } else {
        setData(popup.data);
      }
    }
  }, [popup]);

  useEffect(() => {
    if(!init && excludedPaths !== null) {
      let found = excludedPaths.filter(slug => window.location.href.includes(`/${slug.trim()}`));
      if(!found || found.length === 0 || popup.excludedPaths === '') {
        // not an exlcuded page, check to see if we have already presented within the reminder threshold
        const rData = localRetrieve(key, true);
        if(testMode || !rData || !rData.nextDisplay || rData.nextDisplay <= Date.now()) {
          setDisplay(true);
        }
      }
      setInit(true);
    }
  }, [init, excludedPaths, key, testMode, popup]);

  useEffect(() => {
    if(excludedPaths !== null) {
      let found = excludedPaths.filter(slug => location.pathname.includes(`/${slug.trim()}`));
      if(found && found.length > 0) {
        setDisplay(false);
      }
    }
  }, [location, excludedPaths]);

  const closePopup = () => {
    if(reminder !== 0) {
      let date = new Date();
      date.setDate(date.getDate() + reminder);
      localStore(key, { nextDisplay: date.getTime() }, true);
    }
    onClose();
  };

  const onActionClick = () => {
    if(isNotEmpty(data.actionLink)) {
      window.location = data.actionLink;
    }
    closePopup();
  };

  const onDonationClick = () => {
    window.location = urls.donateFundraiseupStandard;
    closePopup();
  };

  const renderContent = () => {
    return (
      <div className={`FeaturePopup-content type-${type} display-${displayType} text-${alignment}`}>
        <PopupContent 
          type={type}
          title={title}
          description={description}
          snippet={snippet}
          data={data}
          onUpdate={undefined}
        />
      </div>
    );
  };

  const renderActions = () => {
    return (
      <PopupActions 
        type={type}
        data={data} 
        onActionClick={onActionClick} 
        onDonationClick={onDonationClick} 
      />
    );
  };

  return (
    <div className="FeaturePopup">
      { display && type !== null &&
        <Fragment>
          { displayType === 'full' &&
            <FullPageSystemPopup 
              className={`type-${type} display-${displayType} text-${alignment}`}
              open={display} 
              actions={renderActions()}
              onClose={closePopup} 
            >
              {renderContent()}
            </FullPageSystemPopup>
          }
          { displayType === 'standard' &&
            <StandardSystemPopup 
              open={display} 
              className={`type-${type} display-${displayType} text-${alignment}`}
              title={type === 'standard'? title : null}
              actions={renderActions()}
              onClose={closePopup} 
            >
              {renderContent()}
            </StandardSystemPopup>
          }
          { displayType === 'sticky' &&
            <StickySystemPopup 
              open={display} 
              className={`type-${type} display-${displayType} text-${alignment}`}
              actions={renderActions()}
              onClose={closePopup} 
            >
              {renderContent()}
            </StickySystemPopup>
          }
        </Fragment>
      }
    </div>
  );
}

FeaturePopup.propTypes = {
  onClose: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    systemContent: state.systemContent
  };
}

export default connect(mapStateToProps)(FeaturePopup);