import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Icon, { IconTypes } from '../../common/Icon';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MoreIcon from '@mui/icons-material/MoreVert';
import urls from '../../../config/urls';


function SecondaryNonAuth() {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const buttonSelection = url => event => {
    navigate(url);
  }

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.target);
    setMenuOpen(true);
  }

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setMenuOpen(false);
  }

  return (
    <div className="AppHeader-secondaryNavMenu not-authenticated">

      <div className="AppHeader-navigation non-mobile">
        <Button 
          onClick={() => window.location = urls.donateFundraiseupStandard} 
          disableFocusRipple={true} 
          disableRipple={true} 
          variant="contained">
          Donate
        </Button>
        <Button 
          className="login" 
          onClick={buttonSelection(urls.login)} 
          disableFocusRipple={true} 
          disableRipple={true} 
          variant="contained">
          Login
        </Button>
      </div>

      <div className="AppHeader-navigation mobile">
        <div className="AppHeader-menuButton">
          <IconButton aria-haspopup="true" onClick={handleMenuOpen}>
            <MoreIcon />
          </IconButton>
        </div>
      </div>

      <Menu className="AppHeader-navigationMenu menu-mobile" anchorEl={menuAnchor} 
        open={menuOpen} onClose={handleMenuClose}>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon><Icon type={IconTypes.Lock} /></ListItemIcon>
          <ListItemText><Link to={urls.login}>Login</Link></ListItemText>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon><Icon type={IconTypes.Gift} /></ListItemIcon>
          <ListItemText><Link to={urls.donateFundraiseupStandard}>Donate</Link></ListItemText>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon><Icon type={IconTypes.UserMenu} /></ListItemIcon>
          <ListItemText><Link to={urls.memberRegistration}>Join Now</Link></ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default SecondaryNonAuth;